import { gsap } from 'gsap'

console.log('connected')

document.querySelectorAll('[popup-section]').forEach((section) => {
  const ctaButtons = section.querySelectorAll('.agenda_item')
  const closeButtons = section.querySelectorAll('.agenda_close-icon-wrapper')
  const backgrounds = section.querySelectorAll('.agenda_popup-background')

  // Function to open the corresponding modal using GSAP
  ctaButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const slideNumber = button.getAttribute('data-slide')
      const modal = section.querySelector(
        `.agenda_popup-modal[data-slide='${slideNumber}']`
      )
      if (!modal) return

      modal.style.display = 'flex' // Make the modal visible

      gsap.fromTo(
        modal,
        { opacity: 0, pointerEvents: 'none', backdropFilter: 'blur(0px)' },
        {
          opacity: 1,
          pointerEvents: 'auto',
          backdropFilter: 'blur(10px)',
          duration: 0.5,
          ease: 'power2.out',
        }
      )

      gsap.fromTo(
        modal.querySelector('.agenda_popup-content'),
        { scale: 0.8, opacity: 0 },
        { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
      )
    })
  })

  // Function to close the modal using GSAP
  const closeModal = (modal) => {
    gsap.to(modal.querySelector('.agenda_popup-content'), {
      scale: 0.8,
      opacity: 0,
      duration: 0.3,
      ease: 'power2.in',
    })

    gsap.to(modal, {
      opacity: 0,
      duration: 0.3,
      pointerEvents: 'none',
      backdropFilter: 'blur(0px)',
      onComplete: () => {
        modal.style.display = 'none'
      },
    })
  }

  // Add close functionality to close buttons and background click
  closeButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const modal = button.closest('.agenda_popup-modal')
      if (modal) closeModal(modal)
    })
  })

  backgrounds.forEach((background) => {
    background.addEventListener('click', () => {
      const modal = background.closest('.agenda_popup-modal')
      if (modal) closeModal(modal)
    })
  })
})
